import React from 'react';
import PropTypes from 'prop-types';

import SEO from '../components/SEO';
import PageHeading from '../components/PageHeading';
import PageHeroStatic from '../components/PageHeroStatic';
import ContainerWidthImage from '../components/ContainerWidthImage';
import PageWrapper from '../containers/PageWrapper';
import useImages from '../hooks/useImages';
import { plans, plansArray } from '../consts/plans';
import Floorplans from '../components/Floorplans';
import ImageSliderLeft from '../components/ImageSliderLeft';
import ImageSliderRight from '../components/ImageSliderRight';
import PlansListFooter from '../components/PlansListFooter';

Plans.propTypes = {
  pageContext: PropTypes.object.isRequired,
};

export default function Plans({ pageContext: { slug } }) {
  const images = useImages();
  const pageData = plans[slug];

  function renderImageComponents() {
    return pageData.imageSections.map((imageData) => {
      if (typeof imageData === 'string') {
        return <ContainerWidthImage image={images[imageData]} />;
      } else {
        let slides = [];
        for (let i = 0; i < imageData.quantity; i++) {
          slides.push(`${imageData.label}_${i + 1}`);
        }
        slides = slides.map((s) => images[s]);
        if (imageData.direction === 'left')
          return <ImageSliderLeft slides={slides} />;
        else return <ImageSliderRight slides={slides} />;
      }
    });
  }

  return (
    <PageWrapper>
      <SEO
        title={`${pageData.title} | Penrose Grand Del Mar`}
        description="Your new home is surrounded by lush fairways that wind through a serene canyon preserve of the Grand Del Mar."
      />
      <PageHeading title={pageData.title} wd="54rem" wt="48rem" />
      <PageHeroStatic
        title={null}
        image={images[pageData.featuredImage]}
        withCta
      />
      <Floorplans plan={slug} />
      {renderImageComponents()}
      <PlansListFooter plans={plansArray.filter((p) => p.slug !== slug)} />
    </PageWrapper>
  );
}
