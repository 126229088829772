import React, { useState } from 'react';
import useInterval from '@use-it/interval';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import BlueArrow from './Icons/BlueArrow';
import { colors, font } from '../consts/style';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  button {
    padding: 0;
    background: transparent;
    border: none;
    img {
      height: 1.8rem;
    }
  }
  .divider {
    height: 1.6rem;
    width: 1.6rem;
    position: relative;
    &:after {
      content: '';
      left: 0;
      top: calc(50% - 0.05rem);
      height: 0.12rem;
      width: 100%;
      position: absolute;
      background: ${colors.greyLight};
    }
  }
  .nums {
    display: flex;
    align-items: center;
    margin-top: 2.4rem;
  }
  .num {
    position: relative;
    top: 0.2rem;
    ${font.p};
    width: 2rem;
    text-align: center;
    margin: 0 1.8rem;
    position: relative;
    &.black {
      color: black;
    }
    &.grey {
      color: ${colors.greyLight};
    }
  }
`;

export default function SliderControlsStacked({
  slideIndex,
  setIndex,
  length,
  disableAutoSlide,
}) {
  const [delay, setDelay] = useState(4000);

  useInterval(() => {
    if (disableAutoSlide) return null;
    handleNext();
  }, delay);

  function onUserClick(direction) {
    if (direction === 'prev') handlePrev();
    if (direction === 'next') handleNext();
    setDelay(null);
    setTimeout(() => setDelay(4000), 1000);
  }

  function handlePrev() {
    let i = slideIndex === 0 ? length - 1 : slideIndex - 1;
    setIndex(i);
  }

  function handleNext() {
    let i = slideIndex === length - 1 ? 0 : slideIndex + 1;
    setIndex(i);
  }

  return (
    <Wrapper>
      <button onClick={() => onUserClick('next')}>
        <BlueArrow />
      </button>
      <div className="nums">
        <div className={'num left black'}>0{slideIndex + 1}</div>
        <div className="divider" />
        <div className={'num right grey'}>0{length}</div>
      </div>
    </Wrapper>
  );
}

SliderControlsStacked.propTypes = {
  slideIndex: PropTypes.number.isRequired,
  setIndex: PropTypes.func.isRequired,
  length: PropTypes.number.isRequired,
  disableAutoSlide: PropTypes.bool,
};
