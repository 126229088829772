import React from 'react';
import Carousel from 'nuka-carousel';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapped = styled(Carousel)`
  width: 100%;
  max-width: 100%;
  cursor: pointer;
  .slide-visible {
    width: 100% !important;
  }
  .slider-slide {
    width: 100% !important;
    min-height: 40rem;
  }
`;

export default function SimpleCarousel({ slides, ...rest }) {
  return <Wrapped {...rest}>{slides}</Wrapped>;
}

SimpleCarousel.propTypes = {
  slides: PropTypes.array.isRequired,
};
