import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import mq from '../style/mq';
import Fade from './Fade';
import { bz, colors } from '../consts/style';
import { ContainerCompact } from './Elements';

const Wrapper = styled(ContainerCompact)`
  margin-bottom: 14.4rem;
  ${mq.mobile`
  margin-bottom: 9rem;
  `}
`;

const ImageContainer = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  margin-bottom: 6rem;
`;

const ImageWrapper = styled.div`
  width: 100%;
`;

const Image = styled(Img)`
  transition: width 1s;
  top: 0;
  ${bz};
  z-index: 0;
  margin: 0 auto;
  & > img {
    object-fit: 'cover' !important;
    object-position: '50% 50%' !important;
  }
  &::before {
    content: '';
    background: rgba(0, 0, 0, 0);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
`;

export default function ContainerWidthImage({ image }) {
  return (
    <Fade>
      <Wrapper>
        <ImageContainer>
          <ImageWrapper>
            <Image fluid={image} backgroundColor={colors.blue} />
          </ImageWrapper>
        </ImageContainer>
      </Wrapper>
    </Fade>
  );
}

ContainerWidthImage.propTypes = {
  image: PropTypes.object.isRequired,
};
