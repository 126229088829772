import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Img from 'gatsby-image';
import { useInView } from 'react-intersection-observer';

import useImages from '../hooks/useImages';
import { colors, fontOffset, z, font } from '../consts/style';
import SimpleCarousel from './SimpleCarousel';
import mq from '../style/mq';
import Fade from './Fade';
import GreyArrow from './Icons/GreyArrow';
import CancelGreySrc from '../images/cancel-grey.svg';
import SliderControls from './SliderControls';
import BlueArrow from './Icons/BlueArrow';
import { plans } from '../consts/plans';

const Wrapper = styled.div`
  width: 100%;
  max-width: 100vw !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 14.4rem;
  .sal-animate {
    width: 100%;
  }
  ${mq.mobile`
  margin-bottom: 9rem;
  `}
  .buttonWrapper {
    display: flex;
    justify-content: center;
    margin-top: 6rem;
    a {
      &:hover {
        text-decoration: none;
      }
    }
    button {
      ${font.button};
    }
  }
`;

const Inner = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 100vw !important;
  background: ${colors.grey};
  padding: 4rem 10rem;
  ${mq.tablet`
     padding: 4rem 2rem;
  `}
  ${mq.tabletSmall`
    width: 100%;
    padding: 4rem 0;
  `}
`;

const Enlarge = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  width: 100%;
  padding: 0 2rem;
  margin-bottom: 4rem;
  h2 {
    ${font.h2}
  }
  button {
    background: transparent;
    border: none;
    span {
      ${fontOffset}
    }
  }
  ${mq.mobile`
  padding: 2rem;
`}
`;
const Title = styled.div`
  padding-top: 4rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  width: 100%;
  h2 {
    ${font.h2}
  }
`;

const SlideImg = styled(Img)`
  width: 100%;
  max-width: 100%;
`;

const SlideControl = styled.button`
  background: transparent;
  border: none;
  img {
    width: 5rem;
  }
`;

const Left = styled(SlideControl)`
  ${mq.mobile_up`
  transform: translateX(-100%);
 `}
  ${mq.mobile`
  svg {
    width: 3.2rem;
  }
 `}
`;
const Right = styled(SlideControl)`
  ${mq.mobile_up`
  transform: translateX(100%);
 `}
  ${mq.mobile`
  svg {
    width: 3.2rem;
  }
 `}
`;

const ControlWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 2rem;
`;

const CarouselZoomed = css`
  position: fixed;
  z-index: ${z.zoom};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  .slider {
    width: 80vw !important;
    height: auto !important;
    .slider-control-centerleft {
      z-index: ${z.zoom};
      position: fixed !important;
      left: 8rem !important;
    }
    .slider-control-centerright {
      z-index: ${z.zoom};
      position: fixed !important;
      right: 8rem !important;
    }
  }
`;

const CarouselWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 0 5rem;
  max-width: 100vw !important;
  ${mq.tablet`
  padding: 0 8rem;
  `}
  ${mq.mobile`
  padding: 0 2rem;
 `}
 ${(props) => props.zoomed && CarouselZoomed}
`;

const FooterWrapper = styled.div`
  padding: 6rem 0 2rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
  ${mq.tablet`
  padding: 6rem 1.6rem 2rem;
  `}
  ${mq.mobile`
  flex-wrap: wrap;
  justify-content: space-between;
 `}
  .label {
    border-bottom: 1px solid ${colors.greyLight};
    color: ${colors.greyLight};
    text-transform: uppercase;
    font-size: 1.4rem;
    letter-spacing: 0.2em;
    padding: 0.6rem 0;
    margin-bottom: 1.2rem;
  }
  .solo {
    text-align: center;
    width: 100%;
  }
  .caps {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.4rem;
    letter-spacing: 0.2em;
    color: black;
  }
`;

const FooterItem = styled.div`
  width: 100%;
  margin-right: 2rem;
  &:last-child {
    margin-right: 0;
  }
  ${mq.mobile`
  width: 48.5%;
  margin: 0;
 `}
`;

const Cancel = styled.button`
  position: absolute;
  top: 1.6rem;
  right: 1.6rem;
  border: none;
  background: transparent;
`;

const Caption = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background: white;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function Floorplans({ plan }) {
  const [index, setIndex] = useState(0);
  const [zoomed, setZoomed] = useState(false);
  const [hidden, setHidden] = useState(false);

  const images = useImages();

  const planData = plans[plan];

  const [ref, inView] = useInView();

  function handleZoom() {
    setZoomed(!zoomed);
    setHidden(true);
    setTimeout(() => {
      setHidden(false);
    }, 0);
  }

  function handlePrevious() {
    if (index === 0) setIndex(planData.planImages.length - 1);
    else setIndex(index - 1);
  }

  function handleNext() {
    if (index === planData.planImages.length - 1) setIndex(0);
    else setIndex(index + 1);
  }

  function handleImageClick() {
    if (typeof window !== 'undefined' && window.innerWidth > 680) {
      handleZoom();
    }
  }

  function renderFooter() {
    return (
      <FooterWrapper>
        <FooterItem>
          <div className="label">
            <span className="caps">
              {planData.planImages[index] &&
                planData.planImages[index].subtitle}
            </span>
          </div>
          <p>
            {planData.planImages[index] && planData.planImages[index].floor}
          </p>
        </FooterItem>
        <FooterItem>
          <div className="label">
            <span>Area</span>
          </div>
          <p>{planData.planImages[index] && planData.planImages[index].area}</p>
        </FooterItem>
      </FooterWrapper>
    );
  }

  function renderCarousel() {
    const slides = planData.planImages.map((image, i) => (
      <SlideImg key={i} fluid={images[image.file]} />
    ));
    return (
      <CarouselWrapper zoomed={zoomed} ref={ref}>
        {!hidden && inView && (
          <SimpleCarousel
            renderCenterLeftControls={() => (
              <Left zoomed={zoomed} onClick={handlePrevious}>
                {zoomed ? <GreyArrow flip /> : <BlueArrow flip />}
              </Left>
            )}
            renderCenterRightControls={() => (
              <Right zoomed={zoomed} onClick={handleNext}>
                {zoomed ? <GreyArrow /> : <BlueArrow />}
              </Right>
            )}
            renderBottomCenterControls={() => null}
            onClick={handleImageClick}
            slideIndex={index}
            slides={slides}
            dragging={false}
            swiping={false}
          />
        )}
        {!zoomed && (
          <ControlWrapper>
            <SliderControls
              slideIndex={index}
              setIndex={setIndex}
              length={planData.planImages.length}
              disableAutoSlide
            />
          </ControlWrapper>
        )}
        {zoomed && (
          <Cancel onClick={handleZoom}>
            <img src={CancelGreySrc} alt="Exit fullscreen mode" />{' '}
          </Cancel>
        )}
        {zoomed && (
          <Caption>
            <span>
              {planData.title}
              {planData.planImages[index] &&
                ` - ${planData.planImages[index].floor}`}
            </span>
          </Caption>
        )}
      </CarouselWrapper>
    );
  }
  return (
    <Fade>
      <Wrapper>
        <Inner>
          <Title>
            <h2>Floor Plan</h2>
          </Title>
          <Enlarge>
            <button onClick={handleZoom}>
              <span>Click to enlarge</span>
            </button>
          </Enlarge>
          {renderCarousel()}
          {renderFooter()}
        </Inner>
        <div className="buttonWrapper">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={'/downloads/penrose-floorplans-nov-2019.pdf'}
          >
            <button>
              <span>Download Floorplan</span>
            </button>
          </a>
        </div>
      </Wrapper>
    </Fade>
  );
}

Floorplans.propTypes = {
  plan: PropTypes.string.isRequired,
};
