import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { colors } from '../../consts/style';

const Icon = styled.svg`
  transition: 0.3s all;
  &.flip {
    transform: rotate(180deg);
  }
  * {
    transition: 0.3s all;
  }
`;

export default function GreyArrow({ flip }) {
  const [hovered, setHovered] = useState(false);
  return (
    <Icon
      className={flip && 'flip'}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="25"
        cy="25"
        r="19.5"
        stroke={hovered ? colors.orange : colors.greyLight}
        fill={hovered ? colors.orange : 'transparent'}
      />
      <g clipPath="url(#clip0)">
        <path
          d="M22.9497 19.5503L28.3995 25L22.9497 30.4497"
          stroke={hovered ? 'white' : colors.greyLight}
          strokeWidth="2"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(17 33) rotate(-90)"
          />
        </clipPath>
      </defs>
    </Icon>
  );
}

GreyArrow.propTypes = {
  flip: PropTypes.bool,
};
